import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { DashboardLayoutProps } from '../../interfaces/componentsInterface/layout'
import NavControls from "../NavControls";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getRooms } from "../../services/room";
import { roomSelector } from "../../features/room/roomSlice";
import useInterval from "../../hooks/useInterval";
import routes from "../../constants/routes";
import { personalizationSelector } from "../../features/personalization/personalization";
import moment from "moment";
export default function DashboardLayout({ children }: DashboardLayoutProps): ReturnType<React.FC> {
    const [searchValue, setSearchValue] = useState<string>('');
    const dispatch = useAppDispatch();
    const {isFavorite, favoriteRooms, dashboardView } = useAppSelector(personalizationSelector)?.personalization;
    const { sortConfig } = useAppSelector(personalizationSelector).personalization.dashboardView;


    const roomsData = useAppSelector(roomSelector);
    const [filteredRooms, setFilteredRooms] = useState<any[]>([]);


    useInterval(() => {
        if (window.location.pathname === routes.dashboard) {

            dispatch(getRooms());
        }
    }, 5000)

    const getSortedRooms = (filteredRooms: any[], sortConfig: { column: string; order: "asc" | "desc" }) => {
        const sorted = [...filteredRooms];
        const { column, order } = sortConfig;
        sorted.sort((a, b) => {
            let aValue = a[column];
            let bValue = b[column];
            if (column.includes(".")) {
                const [mainKey, subKey] = column.split(".");
                aValue = a[mainKey]?.[subKey] ?? 0;
                bValue = b[mainKey]?.[subKey] ?? 0;
            }
            let comparison = 0;
            if (typeof aValue === "string" && typeof bValue === "string") {
                comparison = order === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            } else if (typeof aValue === "number" && typeof bValue === "number") {
                comparison = order === "asc" ? Number(aValue) - Number(bValue) : Number(bValue) - Number(aValue);
            } else if (aValue && bValue && !isNaN(Date.parse(aValue)) && !isNaN(Date.parse(bValue))) {
                comparison = order === "asc"
                    ? new Date(aValue).getTime() - new Date(bValue).getTime()
                    : new Date(bValue).getTime() - new Date(aValue).getTime();
            }
            if (comparison === 0) {
                comparison = new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            }
            return comparison;
        });

        if (sortConfig.column === "bussUnit") {
            const groupedByBU = sorted.reduce((acc, room) => {
                if (!acc[room.bussUnit]) {
                    acc[room.bussUnit] = [];
                }
                acc[room.bussUnit].push(room);
                return acc;
            }, {} as Record<string, any[]>);
            for (const key in groupedByBU) {
                groupedByBU[key].sort((a: any, b: any) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime());
            }
            return Object.values(groupedByBU).flat();
        }
        return sorted;
    };

    useEffect(() => {
        const filteredRoomsData = roomsData?.rooms.filter((room: any) =>
            room?.roomName?.toLowerCase().includes(searchValue.toLowerCase())
        ) || [];
        if (isFavorite && favoriteRooms?.length > 0) {
            const favoriteRoomIds = favoriteRooms.map((room: any) => room.roomId);
            const favoriteRoomsData = filteredRoomsData.filter((room: any) =>
                favoriteRoomIds.includes(room._id)
            );
            const nonFavoriteRoomsData = filteredRoomsData.filter(
                (room: any) => !favoriteRoomIds.includes(room._id)
            );

            const sortedFavoriteRooms = favoriteRoomsData.sort((a: any, b: any) => {
                const aRoom = favoriteRooms.find((room: any) => room.roomId === a._id);
                const bRoom = favoriteRooms.find((room: any) => room.roomId === b._id);
                if (!aRoom || !bRoom) {
                    console.error(`Room not found: ${a.roomname} or ${b.roomname}`);
                    return 0;
                }
                return moment(bRoom.createdAt).isAfter(moment(aRoom.createdAt)) ? 1 : -1;
            });
            const sortedNonFavoriteRooms = dashboardView?.view === "list" ? getSortedRooms(nonFavoriteRoomsData, sortConfig) : nonFavoriteRoomsData.sort((a: any, b: any) => {
                const aCreatedAt = moment(a.createdAt);
                const bCreatedAt = moment(b.createdAt);
                return bCreatedAt.isAfter(aCreatedAt) ? 1 : -1;
            });
            const finalSortedRooms = [...sortedFavoriteRooms, ...sortedNonFavoriteRooms];
            setFilteredRooms(finalSortedRooms);
        } else {
            if (dashboardView?.view === "list") {
                setFilteredRooms(getSortedRooms(filteredRoomsData, sortConfig));
            } else {
                setFilteredRooms(filteredRoomsData);
            }
        }
    }, [roomsData, isFavorite, favoriteRooms, searchValue, dashboardView, sortConfig]);


    const additionalProps = { filteredRooms: filteredRooms };


    return (

        <>
            <div className="w-screen h-screen p-6 bg-stone-100 flex items-start gap-6">
                <Sidebar />
                <div className="w-[80%] flex flex-col justify-start items-start gap-6">
                    <NavControls searchValue={searchValue} setSearchValue={setSearchValue} />
                    {React.Children.map(children, child => {

                        if (React.isValidElement(child)) {
                            return React.cloneElement(child, { ...additionalProps });
                        }
                        return child;
                    })}
                </div>


            </div>


        </>
    )
}