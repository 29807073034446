import {
  MicrophoneIcon,
  VideoCameraIcon,
  ArrowUpOnSquareIcon,
  ArrowRightStartOnRectangleIcon,
  EllipsisVerticalIcon,
  ClockIcon,
  ClipboardIcon,
  XMarkIcon,
  HandRaisedIcon,
  FaceSmileIcon,
  HandThumbUpIcon,
  HandThumbDownIcon,
  SpeakerXMarkIcon,
  InboxStackIcon,
  ReceiptRefundIcon,
  Cog8ToothIcon,
  TvIcon,
  UserIcon,
  UserGroupIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/outline";

import { Dialog, Transition } from "@headlessui/react";
import Video from "../Video";
import { Fragment, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getInitials } from "../../utils/initials";
import {
  setCameraBackground,
  userDetailsSelector,
} from "../../features/userDetails/userDetailsSlice";
import streamService from "../../services/streamService";
import SocketService from "../../services/socket";
import {
  roomViewModeConst,
  socketConnectiontype,
  higherRoleOptions,
  emojiEventConst,
  AGENT_CAM_ALWAYS_ON,
  VIDEO_BACKGROUND_BLUR,
  VIDEO_BACKGROUND_IMAGES,
  AGENT_CAM_AUTO_ON,
  REACT_APP_CAMERA_OFF_NOTIFICATIONS,
  CAMERA_NOTIFICATION_TIMER_INTERVAL,
} from "../../utils/constant";
import { clearVideoPauseAlertInterval, mediaSelector, setFireAlertForCam, setIsCamOffAlertFired } from "../../features/media/mediaSlice";
import { roomSelector } from "../../features/room/roomSlice";
import MediasoupService from "../../services/mediasoupService";
import { useNavigate } from "react-router-dom";
import UploadVideoAudio from "../UploadVideoAudio";
import VirtualBackground from "../VirtualBackground";
import SignalIndicator from "../SignalIndicator";
import {
  peerVolumeSelector,
  setPeerVolume,
} from "../../features/peerVolume/peerVolumeSlice";
import { getVolumeClasses } from "../../utils/audio";
import { meetingRoomSelector } from "../../features/meetingRoom/meetingRoomSlice";
import { constantMethod } from "../../utils/constantMethod";
import { personalizationSelector } from "../../features/personalization/personalization";
import { environmentSelector } from "../../features/environment/environmentSlice";
import { generateThumbnailsUrl } from "../../utils/generateImageURL";
import routes from "../../constants/routes";
import { FeedbackImageState } from "../../interfaces/componentsInterface/userControls";
import { verifyFaceSelector } from "../../features/verifyFace/verifyFaceSlice";
import useRenderingPipeline from "../../hooks/useRenderingPipeline";
import {
  setVideoOption,
  videoOptionSelector,
} from "../../features/videoOption/videoOptionSlice";

import { SourcePlayback } from "../../helpers/sourceHelper";
import toast from "react-hot-toast";
import { saveBackgroundActivate } from "../../services/userDetails";
import { clearTempRoomUser } from "../../features/tempRoomUser/tempRoomUserSlice";

import ControlActionModal from "../ControlActionModal";
import { getMinuteDifference } from "../../utils/datetimeformat";
import { deviceSelector } from "../../features/device/deviceSlice";
import { setIsVirtualModalOpen, videoBackgroundSelector } from "../../features/videoBackground/videoBackgroundSlice";
import { chatSelector, setEventHRTech, setEventMain, setEventOther, setEventTime } from "../../features/chat/chatSlice";
import { store } from "../../app/store";
import handleClickOutside from "../../utils/click";
import ControlActionModalWithoutTimeLimit from "../ControlActionModalWithoutTimeLimit";
import WarningModal from "../WarningModal";
import { notifyMe } from "../../utils/desktopNotification";
import { updateEvent } from "../../features/alerts/alertsSlice";

const streamInstance = new streamService().getInstance();
const socketInstance = new SocketService(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);
const mediasoupInstance = new MediasoupService().getInstance();
const socketInstanceChat = new SocketService(
  socketConnectiontype.chat
).getInstance(socketConnectiontype.chat);
type ActiveEvents = string | null;

export default function UserControls(props: any): ReturnType<React.FC> {
  const options = [
    {
      Icon: HandRaisedIcon,
      title: "Hand Raise",
      type: "Handraise",
    },
    {
      Icon: NoSymbolIcon,
      title: "DND",
      type: "DND",
    },

    {
      Icon: ReceiptRefundIcon,
      title: "Be Right Back",
      type: "BRB",
    },
    {
      Icon: ClockIcon,
      title: "Break",
      type: "Break",
    },
    {
      Icon: InboxStackIcon,
      title: "Lunch",
      type: "Lunch",
    },
    {
      Icon: UserGroupIcon,
      title: "Meeting",
      type: "Meeting",
    },
    {
      Icon: FaceSmileIcon,
      title: "Smile",
      type: "Smile",
    },
    {
      Icon: HandThumbUpIcon,
      title: "Yes",
      type: "Yes",
    },
    {
      Icon: HandThumbDownIcon,
      title: "No",
      type: "No",
    },
    {
      Icon: Cog8ToothIcon,
      title: "Support",
      type: "technicalissue",
    },
    {
      Icon: SpeakerXMarkIcon,
      title: "Can't hear you",
      type: "Canthear",
    },
    {
      Icon: ClipboardIcon,
      title: "Whiteboard",
      type: "Whiteboard",
    },
    {
      Icon: ArrowUpOnSquareIcon,
      title: "Upload Video/Audio",
      type: "upload",
    },
  ];
  let videoOptions = [
    {
      id: 1,
      title: "Blur Background",
      value: "blur",
      active: false,
    },
    {
      id: 2,
      title: "Virtual Background",
      value: "background",
      active: false,
    },
    {
      id: 3,
      title: "Back to Normal",
      value: "normal",
      active: true,
    },
  ];
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const personalization = useAppSelector(personalizationSelector);
  const environments = useAppSelector(environmentSelector);
  const peerVolume = useAppSelector(peerVolumeSelector);
  const { isVirtualModalOpen } = useAppSelector(videoBackgroundSelector);
  const videoOption = useAppSelector(videoOptionSelector);
  const deviceReducer = useAppSelector(deviceSelector);
  const [imageError, setImageError] = useState(false);
  const spacesConfig =
    environments.environments &&
      environments.environments.REACT_APP_SPACES_CONFIG
      ? environments.environments.REACT_APP_SPACES_CONFIG.data[0]
      : "";
  const screenControl = environments.environments.REACT_APP_SCREENCONTROL
    ? environments.environments.REACT_APP_SCREENCONTROL.status
    : false;

  const isCameraNotificationsEnabled = environments.environments.REACT_APP_CAMERA_OFF_NOTIFICATIONS
  ? environments.environments.REACT_APP_CAMERA_OFF_NOTIFICATIONS.status
  : REACT_APP_CAMERA_OFF_NOTIFICATIONS;

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [feedbackImageData, setFeedbackImageData] =
    useState<FeedbackImageState>({
      file: "",
      agentUsername: "",
      parentRole: "",
      parentUsername: "",
      room: "",
    });

  const [showOptions, setShowOptions] = useState(false);
  const [showVideoOptions, setShowVideoOptions] = useState(false);
  const user = useAppSelector(userDetailsSelector);
  const mediaReducer = useAppSelector(mediaSelector);
  const roomReducer = useAppSelector(roomSelector);
  const { screenCollabPeerArray } = useAppSelector(meetingRoomSelector);
  const [timerModalOpen, setTimerModalOpen] = useState(false);
  const [noLimitTimerModal, setNoLimitTimerModal] = useState(false);
  const [emojiEventTime, setEmojiEventTime] = useState("");
  const [clickedEvent, setClickedEvent] = useState("");
  const [videoInUse, setVideoInUse] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    Icon: "",
    title: "",
    type: "",
  })
  const controlsModalRef = useRef(null);
  const videoOptionsRef = useRef(null);

  var lunchBreakTimeInterval: any = useRef();
  var meetingTimeInterval: any = useRef();
  var DndTimeInterval: any = useRef();

  const [sourcePlayback, setSourcePlayback] = useState<SourcePlayback>();
  const [videoOffOnforEvents, setVideoOffOnforEvents] = useState(false);
  const [isVideoProcessing, setIsVideoProcessing] = useState<boolean>(false);

  const verifyFace = useAppSelector(verifyFaceSelector);
  let messageSubscriberChat: any;

  const blurOption = environments.environments
    ? environments.environments.VIDEO_BACKGROUND_BLUR.status
    : VIDEO_BACKGROUND_BLUR;

  const customBgImg = environments.environments
    ? environments.environments.VIDEO_BACKGROUND_IMAGES.status
    : VIDEO_BACKGROUND_IMAGES;

  const agentCamAlwaysOn = environments.environments.AGENT_CAM_ALWAYS_ON
    ? environments.environments.AGENT_CAM_ALWAYS_ON.status
    : AGENT_CAM_ALWAYS_ON;

  const cameraNotificationsTimerValue = environments.environments.REACT_APP_CAMERA_OFF_NOTIFICATIONS
    ? parseInt(environments.environments.REACT_APP_CAMERA_OFF_NOTIFICATIONS.value)
    : CAMERA_NOTIFICATION_TIMER_INTERVAL;

  const chat = useAppSelector(chatSelector);

  videoOptions = videoOptions.filter((option) => {
    if (option.value === "blur" && !blurOption) {
      return false;
    }
    if (option.value === "background" && !customBgImg) {
      return false;
    }
    return true;
  });

  const sendCamOnAlerts = (peerUserId: string, doFireAlert:boolean) =>{
    // console.log({kkkk5 : props.camOnOffAlertIntervalRef.current});
    if (isCameraNotificationsEnabled && props.camOnOffAlertIntervalRef.current) {
      clearTimeout(props.camOnOffAlertIntervalRef.current);
      props.camOnOffAlertIntervalRef.current = undefined;
    } else if (isCameraNotificationsEnabled && !constantMethod.checkHigherRole(user.role)) {
      const shouldNotFireEvent =
      (store.getState().chat.emojiIconMain &&
        ['BRB', 'Break', 'Lunch', 'DND', 'Meeting'].includes(store.getState().chat.emojiIconMain)) ||
      // (mediaReducer.fireAlertForAgenCam && (agentCamAlwaysOn || agentCamAutoTurnOn)) ||
      doFireAlert;
      // console.log({kkk : mediaReducer.fireAlertForAgenCam, agentCamAlwaysOn, agentCamAutoTurnOn, shouldNotFireEvent})
      if (shouldNotFireEvent) {
        console.log("No event to be fired in this");
        clearTimeout(props.camOnOffAlertIntervalRef.current);
        props.camOnOffAlertIntervalRef.current = undefined;
        store.dispatch(setFireAlertForCam());
      } else if(mediaReducer.isCamOffAlertFired){
        socketInstance.sendMessage("GET_ALERT_NOTIFICATION_FOR_CAMERA_ON_OFF", {
          msg: "ON",
          peerUserId: peerUserId,
        });
        store.dispatch(setIsCamOffAlertFired(false));
      }
    }
  }

  const sendCamOffAlerts = (peerUserId: string) => {
    if (isCameraNotificationsEnabled && !constantMethod.checkHigherRole(user.role)) {
      // Set a new timeout
      props.camOnOffAlertIntervalRef.current = setTimeout(() => {
        if (store.getState().chat.emojiIconMain &&
          ['BRB', 'Break', 'Lunch', 'DND', 'Meeting'].includes(store.getState().chat.emojiIconMain)) {
          store.dispatch(setIsCamOffAlertFired(false));
        } else {
          socketInstance.sendMessage("GET_ALERT_NOTIFICATION_FOR_CAMERA_ON_OFF", {
            msg: "OFF",
            peerUserId: peerUserId
          });
          toast("Your Camera is Off");
          notifyMe("Your Camera is Off");
          store.dispatch(setIsCamOffAlertFired(true));
        }

        clearTimeout(props.camOnOffAlertIntervalRef.current);
        props.camOnOffAlertIntervalRef.current = undefined;
      }, Number(cameraNotificationsTimerValue * 60 * 1000));
    }
  }


  function closeUploadModal() {
    setIsUploadModalOpen(false);
  }

  function openUploadModal() {
    setIsUploadModalOpen(true);
  }

  function closeVirtualModal() {
    dispatch(setIsVirtualModalOpen(false));
  }

  function openNoLimitTimerModal() {
    setNoLimitTimerModal(true);
  }

  function openTimerModal() {
    setTimerModalOpen(true);
  }

  function closeTimerModal() {
    console.log("CLOSE CALL");
    setEmojiEventTime("");
    dispatch(setEventTime(""));
    clearInterval(lunchBreakTimeInterval.current);

    if (clickedEvent === emojiEventConst.BRB) {
      socketInstanceChat.sendMessage("EMOJI_FOR_BRB", {
        roomId: roomReducer.currentRoomId,
        roomname: roomReducer.currentRoom,
        type: "",
        time: "",
        flag: "endbrb",
        userId: user.userId,
      });

       // For Reporting purpose send tomedia microservice
       socketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_ICON", {
        roomId: roomReducer.currentRoomId,
        type: "",
        time: "",
        flag: "berightback",
        peerUserId: user.userId,
      });
      // if (videoOffOnforEvents) {
      // console.log("############# video unpaused 4")
      //   streamInstance.unpauseVideo(user.userId);
      //   setVideoOffOnforEvents(false);
      // }
    }
    if (clickedEvent === emojiEventConst.LUNCH) {
      socketInstanceChat.sendMessage("EMOJI_FOR_BREAKANDLUNCH", {
        roomname: roomReducer.currentRoom,
        roomId: roomReducer.currentRoomId,
        type: "",
        time: "",
        flag: "endlunch",
        userId: user.userId,
      });

       // For Reporting purpose send tomedia microservice
       socketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_BREAKANDLUNCH", {
        roomId: roomReducer.currentRoomId,
        type: "",
        time: "",
        flag: "lunch",
        peerUserId: user.userId,
      });
      // if (videoOffOnforEvents) {
      //   console.log("############# video unpaused 1");
      //   streamInstance.unpauseVideo(user.userId);
      //   setVideoOffOnforEvents(false);
      // }
    }
    if (clickedEvent === emojiEventConst.BREAK) {
      socketInstanceChat.sendMessage("EMOJI_FOR_BREAKANDLUNCH", {
        roomname: roomReducer.currentRoom,
        roomId: roomReducer.currentRoomId,
        type: "",
        time: "",
        flag: "endbreak",
        userId: user.userId,
      });

       // For Reporting purpose send tomedia microservice
       socketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_BREAKANDLUNCH", {
        roomId: roomReducer.currentRoomId,
        type: "",
        time: "",
        flag: "break",
        peerUserId: user.userId,
      });
    }

    setClickedEvent("");
    dispatch(setEventMain(""));
    dispatch(setEventOther(""));
    dispatch(setEventHRTech(""));
    setTimerModalOpen(false);
    if (videoOffOnforEvents) {
      streamInstance.unpauseVideo(user.userId, undefined , true);
      sendCamOnAlerts(user.userId, true);
      setVideoOffOnforEvents(false);
    }
  }

  async function handleDndEvent() {
      setEmojiEventTime("");
      dispatch(setEventTime(""));
      clearInterval(DndTimeInterval.current);
      if (chat?.emojiIconMain === emojiEventConst.DND) {
      await socketInstanceChat.sendMessage("EMOJI_FOR_DND", {
        roomname: roomReducer.currentRoom,
        roomId: roomReducer.currentRoomId,
        type: "",
        time: "",
        flag: "enddnd",
        userId: user.userId,
      });

      // For Reporting purpose send tomedia microservice
      await socketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_DND", {
        roomId: roomReducer.currentRoomId,
        type: "",
        time: "",
        flag: "DND",
        peerUserId: user.userId,
      });
    }

      setClickedEvent("");
      dispatch(setEventMain(""));

    if(selectedOption.type !==""){
      handleOptionClick(selectedOption);
    }
  }

  function closeModalNoLimit() {
    console.log("CLOSE CALL");
    setEmojiEventTime("");
    dispatch(setEventTime(""));
    clearInterval(meetingTimeInterval.current);
    if (clickedEvent === emojiEventConst.MEETING) {
      socketInstanceChat.sendMessage("EMOJI_FOR_MEETING", {
        roomname: roomReducer.currentRoom,
        roomId: roomReducer.currentRoomId,
        type: "",
        time: "",
        flag: "endmeeting",
        userId: user.userId,
      });
       socketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_MEETING", {
        roomId: roomReducer.currentRoomId,
        type: "",
        time: "",
        flag: "meeting",
        peerUserId: user.userId,
      });
    }
    setClickedEvent("");
    dispatch(setEventMain(""));
    dispatch(setEventOther(""));
    dispatch(setEventHRTech(""));
    setNoLimitTimerModal(false);
    if (videoOffOnforEvents) {
      streamInstance.unpauseVideo(user.userId, undefined , true);
      sendCamOnAlerts(user.userId, true);
      setVideoOffOnforEvents(false);
    }
  }

  function openVirtualModal() {
    dispatch(setIsVirtualModalOpen(true));
  }
  const handleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  const closeShowOptions = () => {
    setShowOptions(false);
  }

  const handleToggleVideoOptions = () => {
    setShowVideoOptions(!showVideoOptions);
  };

  const closeVideoOptions = () => {
    setShowVideoOptions(false);
  }

  // const toggleActiveEvent = (type: string): void => {
  //   setActiveEvents(prev => ({ ...prev, [type]: !(prev[type] ?? false) }));
  // };

  const { canvasRef, canvasDisplayRef } = useRenderingPipeline(
    // @ts-ignore
    sourcePlayback,
    props.segmentationConfig,
    props.tflite,
    streamInstance.localVideoStream && !mediaReducer.localVideoPaused
  );

  const handleVideoOptionsClick = (value: string) => {
    switch (value) {
      case "background":
        console.log("IAMBACKGROUND");
        openVirtualModal();
        setShowVideoOptions(false);
        break;

      case "blur":
        console.log({ tflte: props.tflite });

        if (!props.tflite) {
          toast.error(
            "There is some issue with background modal loading. Please refresh page."
          );
          return;
        }

        dispatch(setVideoOption(value));
        dispatch(saveBackgroundActivate({ backgroundType: value, url: "" }));
        dispatch(setCameraBackground({ backgroundType: value, url: "" }));
        setShowVideoOptions(false);

        break;

      case "normal":
        dispatch(setVideoOption(value));
        dispatch(saveBackgroundActivate({ backgroundType: value, url: "" }));
        dispatch(setCameraBackground({ backgroundType: value, url: "" }));
        setShowVideoOptions(false);
        break;
    }
  };



  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => handleClickOutside(e, controlsModalRef, closeShowOptions);

    if (showOptions) {
      document.addEventListener('mousedown', handleDocumentClick);
    } else {
      document.removeEventListener('mousedown', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [showOptions]);


  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => handleClickOutside(e, videoOptionsRef, closeVideoOptions);

    if (showVideoOptions) {
      document.addEventListener('mousedown', handleDocumentClick);
    } else {
      document.removeEventListener('mousedown', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [showVideoOptions]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    messageSubscriberChat = socketInstanceChat
      .getMessages()
      .subscribe((message: string) => {
        processMessage(JSON.parse(message));
      });
    return () => {
      messageSubscriberChat.unsubscribe();


      // if (socketInstanceChat) {
      //   socketInstanceChat.closeSocketConnection();
      //   socketInstanceChat.socket = undefined;
      //   socketInstanceChat.reconnectFlushState();
      // }
    };
  }, []);
  const processMessage = async (message: any) => {
    switch (message.type) {
      case "SELF_CONNECTION_ACKNOWLEDGE":
        if (socketInstanceChat.ifReconnect) {
          let chatReducer = store.getState().chat;

          if (chatReducer.emojiIconMain !== "") {
            dispatch(updateEvent({
              userId: user.userId,
              event: chatReducer.emojiIconMain,
              endTime: Date.now()
            }))
          }
          if (chatReducer.emojiIconMain !== "" && chatReducer.emojiIconMain !== "DND" && chatReducer.emojiIconMain !== "Meeting") {
            let timeDiff1 = getMinuteDifference(chatReducer.eventTime);
            await socketInstanceChat.sendMessage("UPDATE_TIMER_LUNCH_BREAK_BRB", {
              roomname: roomReducer.currentRoom,
              roomId: roomReducer.currentRoomId,
              type: chatReducer.emojiIconMain,
              time: timeDiff1,
              userId: user.userId,
              reconnectCase: true
            });
          }
          if (chatReducer.emojiIconMain === "DND") {
            let timeDiff1 = getMinuteDifference(chatReducer.eventTime);
            await socketInstanceChat.sendMessage("UPDATE_TIMER_DND", {
              roomname: roomReducer.currentRoom,
              roomId: roomReducer.currentRoomId,
              type: chatReducer.emojiIconMain,
              time: timeDiff1,
              userId: user.userId,
              reconnectCase: true
            });
          }

          if (chatReducer.emojiIconMain === "Meeting") {
            let timeDiff1 = getMinuteDifference(chatReducer.eventTime);
            await socketInstanceChat.sendMessage("UPDATE_TIMER_MEETING", {
              roomname: roomReducer.currentRoom,
              roomId: roomReducer.currentRoomId,
              type: chatReducer.emojiIconMain,
              time: timeDiff1,
              userId: user.userId,
              reconnectCase: true
            });
          }

          if (chatReducer.emojiIconOther !== "") {
            await socketInstanceChat.sendMessage("EMOJI_OTHER_ACTIONS", {
              roomname: roomReducer.currentRoom,
              roomId: roomReducer.currentRoomId,
              type: chatReducer.emojiIconOther,
              userId: user.userId,
              reconnectCase: true
            });
          }

          if (chatReducer.emojiIconHRTech !== "") {
            await socketInstanceChat.sendMessage("EMOJI_FOR_HANDRAISEANDTECHISSUE", {
              roomname: roomReducer.currentRoom,
              roomId: roomReducer.currentRoomId,
              type: chatReducer.emojiIconHRTech,
              userId: user.userId,
              reconnectCase: true
            });
          }

          socketInstanceChat.ifReconnect = false;
        }
        if (socketInstanceChat.socket) {
          socketInstanceChat.disconnectSocketId = socketInstanceChat.socket?.id;
        }
        break

      default:
        break;
    }
  };


  useEffect(() => {
    setTimeout(() => {
      let track;
      if (videoOption.videoOption === "normal") {
        const node: HTMLVideoElement = document.getElementsByClassName(
          "face_detection_node"
        )[0] as HTMLVideoElement;
        // @ts-ignore
        track = node?.srcObject?.getVideoTracks()[0];
      } else {
        const node = document.getElementById("blur");

        // @ts-ignore
        track = node?.captureStream()?.getVideoTracks()[0];
      }
      mediasoupInstance.replaceVideoTrack(track);
    }, 200);
  }, [videoOption.videoOption]);


  // useEffect(() => {
  //   const checkVideoDeviceStatus = async () => {
  //     try {
  //       const devices = await navigator.mediaDevices.enumerateDevices();
  //       const videoDevices = devices.filter(device => device.kind  
  //  === 'videoinput');

  //       if (videoDevices.length > 0) {
  //         let isInUse = false;
  //         try {
  //           const stream = await navigator.mediaDevices.getUserMedia({ video: true });
  //           const videoTracks = stream.getVideoTracks();


  //           isInUse = videoTracks.some(track => track.readyState === 'live');


  //           videoTracks.forEach(track => track.stop());
  //         } catch (err) {

  //           console.error('Error accessing video devices:', err);
  //           toast.error('An error occurred while accessing your camera.');
  //           isInUse = true;
  //           setVideoInUse(isInUse)
  //           return;
  //         }

  //         if(document.title !== "CollaborationRoom.AI"){

  //           if (isInUse) {
  //             toast.error('Camera is currently in use by another application.');
  //           }
  //           setVideoInUse(isInUse);
  //         }
  //       } else {
  //         setVideoInUse(false);
  //       }
  //     } catch (error) {
  //       console.error('Error accessing video devices:', error);
  //       toast.error('An error occurred while accessing your camera.');
  //       setVideoInUse(false);
  //     }
  //   };

  //   // Introduce a delay if needed
  //   setTimeout(() => {
  //     checkVideoDeviceStatus();
  //   }, 500); // Adjust delay as needed
  // }, []);
  // const requestCameraAccess = async () => {
  //   try {
  //     // Request camera access for this website
  //     setCameraRequested(true);
  //     await navigator.mediaDevices.getUserMedia({ video: true });
  //   } catch (err) {
  //     console.error('Error requesting camera access:', err);
  //     toast.error('Failed to access camera.');
  //   }
  // };

  async function handleEmojiIconOtherActions() {
    const emojiIconOther = store.getState().chat.emojiIconOther;
    const emojiIconHRTech = store.getState().chat.emojiIconHRTech;
    if (emojiIconOther) {
      let type = emojiIconOther;
      if (type === "Canthear") type = "Cannothear";

      // Send clear actions for the emoji
      await socketInstanceChat.sendMessage("EMOJI_OTHER_ACTIONS", {
        roomname: roomReducer.currentRoom,
        roomId: roomReducer.currentRoomId,
        type: "",
        userId: user.userId,
      });

      await socketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_ICON", {
        roomId: roomReducer.currentRoomId,
        type: "",
        flag: type,
        peerUserId: user.userId,
      });
    }
    if (emojiIconHRTech) {
      let type = emojiIconHRTech;
      if (type === "Handraise") type = "Handraised";
      // Send clear actions for the emoji handraise and tech support
      await socketInstanceChat.sendMessage("EMOJI_FOR_HANDRAISEANDTECHISSUE", {
        roomname: roomReducer.currentRoom,
        roomId: roomReducer.currentRoomId,
        type: "",
        userId: user.userId,
      });
  
      await socketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_HANDRAISEANDTECHNICALISSUE", {
        roomId: roomReducer.currentRoomId,
        type: "",
        flag: type,
        peerUserId: user.userId,
      });
    }
    dispatch(setEventOther(""));
    dispatch(setEventHRTech(""));
  }

  const handleOptionClick = async (option: {
    Icon: any;
    title: string;
    type: string;
  }) => {
    setShowOptions(false);
    if((option.type==="Lunch" || option.type==="Break" || option.type==="BRB" || option.type==="Meeting") && store.getState().chat?.emojiIconMain===emojiEventConst.DND ){
      setSelectedOption(option);
      setEmojiEventTime("");
      props.dndModalControl(true);
      return;
    }else{
      setSelectedOption({
        Icon: "",
        title: "",
        type: "",
      });
    }

    switch (option.type) {
      case "upload":
        openUploadModal();
        break;

      case "Break":
        //first clean the handraise or other status
        await handleEmojiIconOtherActions();
        // continue as usual
        clearInterval(lunchBreakTimeInterval.current);
        setClickedEvent(option.type);
        dispatch(setEventMain(option.type));
        openTimerModal();
        let tempBreakTime = "";
        if (emojiEventTime === "") {
          tempBreakTime = new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });
          setEmojiEventTime(tempBreakTime);
          dispatch(setEventTime(tempBreakTime))
        } else {
          tempBreakTime = emojiEventTime;
        }
        let timeDiffBreak = getMinuteDifference(tempBreakTime);
        await socketInstanceChat.sendMessage("EMOJI_FOR_BREAKANDLUNCH", {
          roomname: roomReducer.currentRoom,
          roomId: roomReducer.currentRoomId,
          type: option.type,
          time: timeDiffBreak,
          flag: "startbreak",
          userId: user.userId,
        });

        // For Reporting purpose send tomedia microservice
        socketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_BREAKANDLUNCH", {
          roomId: roomReducer.currentRoomId,
          type: option.type,
          time: timeDiffBreak,
          peerUserId: user.userId,
        });

        lunchBreakTimeInterval.current = window.setInterval(async () => {
          let timeDiff1 = getMinuteDifference(tempBreakTime);
          await socketInstanceChat.sendMessage("UPDATE_TIMER_LUNCH_BREAK_BRB", {
            roomname: roomReducer.currentRoom,
            roomId: roomReducer.currentRoomId,
            type: option.type,
            time: timeDiff1,
            userId: user.userId,
            reconnectCase: false
          });
        }, 60000);
        // dispatch(clearVideoPauseAlertInterval());
        // clearTimeout(props.camOnOffAlertIntervalRef.current);
        if (!mediaReducer.localVideoPaused) {
          setVideoOffOnforEvents(true);
          streamInstance.pauseVideo(user.userId);
          // console.log({kkkk1_OFF : props.camOnOffAlertIntervalRef.current});
          sendCamOffAlerts(user.userId);
        }

        break;
      case "Lunch":
        //first clean the handraise or other status
        await handleEmojiIconOtherActions();
        // continue as usual
        clearInterval(lunchBreakTimeInterval.current);
        setClickedEvent(option.type);
        dispatch(setEventMain(option.type));
        openTimerModal();
        let tempLunchTime = "";
        if (emojiEventTime === "") {
          tempLunchTime = new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });
          setEmojiEventTime(tempLunchTime);
          dispatch(setEventTime(tempLunchTime))
        } else {
          tempLunchTime = emojiEventTime;
        }
        let timeDiffLunch = getMinuteDifference(tempLunchTime);
        await socketInstanceChat.sendMessage("EMOJI_FOR_BREAKANDLUNCH", {
          roomname: roomReducer.currentRoom,
          roomId: roomReducer.currentRoomId,
          type: option.type,
          time: timeDiffLunch,
          flag: "startlunch",
          userId: user.userId,
        });

        // For Reporting purpose send tomedia microservice
        socketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_BREAKANDLUNCH", {
          roomId: roomReducer.currentRoomId,
          type: option.type,
          time: timeDiffLunch,
          peerUserId: user.userId,
        });

        lunchBreakTimeInterval.current = window.setInterval(async () => {
          let timeDiff1 = getMinuteDifference(tempLunchTime);
          await socketInstanceChat.sendMessage("UPDATE_TIMER_LUNCH_BREAK_BRB", {
            roomname: roomReducer.currentRoom,
            roomId: roomReducer.currentRoomId,
            type: option.type,
            time: timeDiff1,
            userId: user.userId,
            reconnectCase: false
          });
        }, 60000);
        // dispatch(clearVideoPauseAlertInterval());
        // clearTimeout(props.camOnOffAlertIntervalRef.current);
        if (!mediaReducer.localVideoPaused) {
          setVideoOffOnforEvents(true);
          streamInstance.pauseVideo(user.userId);
          // console.log({kkkk2_OFF : props.camOnOffAlertIntervalRef.current});
          sendCamOffAlerts(user.userId);
        }
        break;
      case "DND":
        if (chat?.emojiIconMain === emojiEventConst.DND) {
          props.dndModalControl(true);
        } else {
          clearInterval(DndTimeInterval.current);
          setClickedEvent(option.type);
          dispatch(setEventMain(option.type));
          props.startDndTimer();
          let tempDndTime = "";
          if (emojiEventTime === "") {
            tempDndTime = new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
            setEmojiEventTime(tempDndTime);
            dispatch(setEventTime(tempDndTime))
          } else {
            tempDndTime = emojiEventTime;
          }
          let timeDiffDnd = getMinuteDifference(tempDndTime);
          await socketInstanceChat.sendMessage("EMOJI_FOR_DND", {
            roomname: roomReducer.currentRoom,
            roomId: roomReducer.currentRoomId,
            type: option.type,
            time: timeDiffDnd,
            flag: "startdnd",
            userId: user.userId,
          });

          // For Reporting purpose send tomedia microservice
          socketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_DND", {
            roomId: roomReducer.currentRoomId,
            type: option.type,
            time: timeDiffDnd,
            peerUserId: user.userId,
          });

          DndTimeInterval.current = window.setInterval(async () => {
            let timeDiff1 = getMinuteDifference(tempDndTime);
            await socketInstanceChat.sendMessage("UPDATE_TIMER_DND", {
              roomname: roomReducer.currentRoom,
              roomId: roomReducer.currentRoomId,
              type: option.type,
              time: timeDiff1,
              userId: user.userId,
              reconnectCase: false
            });
          }, 60000);
        }
        break;
      case "Meeting":
        //first clean the handraise or other status
        await handleEmojiIconOtherActions();
        clearInterval(meetingTimeInterval.current);
        setClickedEvent(option.type);
        dispatch(setEventMain(option.type));
        openNoLimitTimerModal();
        let tempMeetingTime = "";
        if (emojiEventTime === "") {
          tempMeetingTime = new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });
          setEmojiEventTime(tempMeetingTime);
          dispatch(setEventTime(tempMeetingTime))
        } else {
          tempMeetingTime = emojiEventTime;
        }
        let timeDiffMeeting = getMinuteDifference(tempMeetingTime);
        await socketInstanceChat.sendMessage("EMOJI_FOR_MEETING", {
          roomname: roomReducer.currentRoom,
          roomId: roomReducer.currentRoomId,
          type: option.type,
          time: timeDiffMeeting,
          flag: "startmeeting",
          userId: user.userId,
        });

        // For Reporting purpose send to media microservice
        socketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_MEETING", {
          roomId: roomReducer.currentRoomId,
          type: option.type,
          time: timeDiffMeeting,
          peerUserId: user.userId,
        });

        meetingTimeInterval.current = window.setInterval(async () => {
          let timeDiff1 = getMinuteDifference(tempMeetingTime);
          await socketInstanceChat.sendMessage("UPDATE_TIMER_MEETING", {
            roomname: roomReducer.currentRoom,
            roomId: roomReducer.currentRoomId,
            type: option.type,
            time: timeDiff1,
            userId: user.userId,
            reconnectCase: false
          });
        }, 60000);
        // dispatch(clearVideoPauseAlertInterval());
        // clearTimeout(props.camOnOffAlertIntervalRef.current);
        if (!mediaReducer.localVideoPaused) {
          setVideoOffOnforEvents(true);
          streamInstance.pauseVideo(user.userId);
          // console.log({kkkk3_OFF : props.camOnOffAlertIntervalRef.current});
          sendCamOffAlerts(user.userId);
        }
        if (!mediaReducer.localAudioPaused) {
          streamInstance.pauseLocalMic(user.userId);
        }
        break;
      case "BRB":
        //first clean the handraise or other status
        await handleEmojiIconOtherActions();
        // continue as usual
        clearInterval(lunchBreakTimeInterval.current);
        openTimerModal();
        setClickedEvent(option.type);
        dispatch(setEventMain(option.type));

        let tempTime = "";
        if (emojiEventTime === "") {
          tempTime = new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });

          setEmojiEventTime(tempTime);
          dispatch(setEventTime(tempTime))
        } else {
          tempTime = emojiEventTime;
        }
        let timeDiff = getMinuteDifference(tempTime);

        await socketInstanceChat.sendMessage("EMOJI_FOR_BRB", {
          roomname: roomReducer.currentRoom,
          roomId: roomReducer.currentRoomId,
          type: option.type,
          time: timeDiff,
          flag: "startbrb",
          userId: user.userId,
          reconnectCase: false
        });

        // For Reporting purpose send tomedia microservice
        socketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_ICON", {
          roomId: roomReducer.currentRoomId,
          type: "berightback",
          peerUserId: user.userId,
        });


        lunchBreakTimeInterval.current = window.setInterval(async () => {
          let timeDiff1 = getMinuteDifference(tempTime);
          await socketInstanceChat.sendMessage("UPDATE_TIMER_LUNCH_BREAK_BRB", {
            roomname: roomReducer.currentRoom,
            roomId: roomReducer.currentRoomId,
            type: option.type,
            time: timeDiff1,
            userId: user.userId,
            reconnectCase: false
          });
        }, 60000);
        // dispatch(clearVideoPauseAlertInterval());
        // clearTimeout(props.camOnOffAlertIntervalRef.current);
        if (!mediaReducer.localVideoPaused) {
          setVideoOffOnforEvents(true);
          streamInstance.pauseVideo(user.userId);
          // console.log({kkkk4_OFF : props.camOnOffAlertIntervalRef.current});
          sendCamOffAlerts(user.userId);
        }
        break;
      case "Smile":
      case "Canthear":
      case "Yes":
      case "No":
        console.log({
          userId: user.userId,
          peersId: mediaReducer.remotePeersId,
        });
        // setActiveEvents(newActiveState);

        let typeOther = option.type.toString();
        if (typeOther === "Canthear") typeOther = "Cannothear";

        if (store.getState().chat.emojiIconHRTech === option.type) {
          // Action to perform when toggling OFF
          socketInstanceChat.sendMessage("EMOJI_OTHER_ACTIONS", {
            roomname: roomReducer.currentRoom,
            roomId: roomReducer.currentRoomId,
            type: "",
            userId: user.userId,
          });
          socketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_ICON", {
            roomId: roomReducer.currentRoomId,
            type: "",
            flag: typeOther,
            peerUserId: user.userId,
          });
          // setClickedEvent("");
          dispatch(setEventOther(""));
        }else {
          // Action to perform when toggling ON
          await socketInstanceChat.sendMessage("EMOJI_OTHER_ACTIONS", {
            roomname: roomReducer.currentRoom,
            roomId: roomReducer.currentRoomId,
            type: option.type,
            userId: user.userId,
            reconnectCase: false
          });

          // For Reporting purpose send tomedia microservice
          socketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_ICON", {
            roomId: roomReducer.currentRoomId,
            type: typeOther,
            peerUserId: user.userId,
          });

          // Set the emoji as active
          // setClickedEvent(option.type);

          dispatch(setEventOther(option.type));
        }

        break;
      case "technicalissue":
      case "Handraise":
        console.log({
          userId: user.userId,
          peersId: mediaReducer.remotePeersId,
        });

        let type = option.type.toString();
        if (type === "Handraise") type = "Handraised";

        if (store.getState().chat.emojiIconHRTech === option.type) {
          // Action to perform when toggling OFF
          socketInstanceChat.sendMessage("EMOJI_FOR_HANDRAISEANDTECHISSUE", {
            roomname: roomReducer.currentRoom,
            roomId: roomReducer.currentRoomId,
            type: "",
            userId: user.userId,
          });
          socketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_HANDRAISEANDTECHNICALISSUE", {
            roomId: roomReducer.currentRoomId,
            type: "",
            flag: type,
            peerUserId: user.userId,
          });
          // setClickedEvent("");
          dispatch(setEventHRTech(""));
        } else {
          // Action to perform when toggling ON
          await socketInstanceChat.sendMessage("EMOJI_FOR_HANDRAISEANDTECHISSUE", {
            roomname: roomReducer.currentRoom,
            roomId: roomReducer.currentRoomId,
            type: option.type,
            userId: user.userId,
            reconnectCase: false
          });

          // For Reporting purpose send tomedia microservice
          socketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_HANDRAISEANDTECHNICALISSUE", {
            roomId: roomReducer.currentRoomId,
            type: type,
            peerUserId: user.userId,
          });

          // Set the emoji as active
          // setClickedEvent(option.type);
          dispatch(setEventHRTech(option.type));
        }

        break;
      case "Whiteboard":
        socketInstanceChat.sendMessage("SEND_CHAT_MESSAGE", {
          message: `${window.location.protocol}//${window.location.hostname === "localhost"
            ? window.location.hostname + ":3000"
            : window.location.hostname
            }${routes.whiteboard}/${roomReducer.currentRoom}/${roomReducer.currentRoomId
            }`,
          chatType: "SAMEROOM",
          sender: {
            senderSocketId: "",
            senderUsername: user.username,
            senderFullname: user.firstName + " " + user.lastName,
            senderUserId: user.userId,
            senderRole: user.role,
          },
          receiver: {
            receiverSocketId: "",
            receiverUsername: "",
            receiverFullname: "",
            receiverUserId: "",
            receiverRole: "",
          },
          client: { clientname: user.custCode, clientId: user.custId },
          room: {
            roomName: roomReducer.currentRoom,
            roomId: roomReducer.currentRoomId,
          },
          contentType: "text",
          file: { filename: "", fileurl: "" },
          bussId: roomReducer.currentRoomBussId
        });

        // socketInstanceWB.sendMessage("NEW_WHITEBOARD", {
        //     roomname: roomDetails.currentRoom,
        // });
        break;
    }
  };
  const videoPauseOrUnpause = async () => {
    if (isVideoProcessing) return; // Prevent multiple triggers
    setIsVideoProcessing(true);
    try{

      const checkVideoPaused = mediaReducer.localVideoPaused;
       console.log("Video pause or unpause", checkVideoPaused);
     if (checkVideoPaused) {
       await streamInstance.startVideoStream(deviceReducer.activeVideoDevice, props.faceDetection);
      if(store.getState().reconnectionStatus.isReconnectionStatusVideo){
        // console.log({kkkk3 : props.camOnOffAlertIntervalRef.current});
        sendCamOnAlerts(user.userId, true);
      }else {
        // console.log({kkkk4 : props.camOnOffAlertIntervalRef.current});
        sendCamOnAlerts(user.userId, false);
      }
       // streamInstance.unpauseVideo(user.userId);
       if (
         !constantMethod.checkHigherRole(user.role) &&
         props.faceDetectionFlag
       ) {
         props.faceDetection();
       }
     } else {
       // streamInstance.pauseVideo(user.userId);
       mediasoupInstance.videoProducer?.close();
       streamInstance.pauseVideo(user.userId);
       // console.log({kkkk5_OFF : props.camOnOffAlertIntervalRef.current});
       sendCamOffAlerts(user.userId);
       if (streamInstance.localVideoStream) {
         streamInstance.localVideoStream.getVideoTracks().forEach(function (track: any) {
           track.stop();
         });
         streamInstance.localVideoStream = null;
       }
       if (streamInstance.videoTrack) {
         streamInstance.videoTrack.stop();
         streamInstance.videoTrack = null;
       }
       setShowVideoOptions(false);
       clearInterval(props.frstart.current);
     }
    }catch(error){

    console.error("Error toggling video", error);
    } finally {
      setIsVideoProcessing(false);
    }

  };

  const goToDashboard = async () => {
    await handleDndEvent();
    // dispatch(clearVideoPauseAlertInterval());
    dispatch(setIsCamOffAlertFired(false));
    await props.clearBeforeUnmount();
    dispatch(clearTempRoomUser());
    // setTimeout(() => {
      navigate(routes.dashboard);
    // }, 500)
  };

  // socketInstanceWB.sendMessage("NEW_WHITEBOARD", {
  //     roomname: roomReducer.currentRoom,
  // });

  const getEncodingsForScreen = () => {
    return [
      {
        maxBitrate: roomReducer.videoScale.maxBitrate,
        scaleResolutionDownBy: roomReducer.videoScale.HIGH,
        // constantMethod.checkHigherRole(user.role) ||
        //     roomReducer.viewMode === roomViewModeConst.collabMode || mediaReducer.socketId === this.state.presenterForAgent
        //     ? resolutionConstant.high
        //     : resolutionConstant.low,
      },
    ];
  };

  const startStopScreen = () => {
    let screenStopped = mediaReducer.localScreenStopped;
    if (screenStopped) {
      streamInstance.shareScreen(user.userId);
    } else {
      streamInstance.unshareScreen(user.userId);
    }
  };

  const audioPauseOrUnpause = () => {
    const checkAudioPaused = mediaReducer.localAudioPaused;

    if (checkAudioPaused) {
      streamInstance.unpauseLocalMic(user.userId);
    } else {
      streamInstance.pauseLocalMic(user.userId);
      dispatch(setPeerVolume({ volume: -100, peerUserId: user.userId }));
    }
  };

  const startStopScreenTwo = () => {
    let screenStopped = mediaReducer.localScreenStoppedTwo;
    if (screenStopped) {
      streamInstance.shareScreenTwo(user.userId);
    } else {
      streamInstance.unshareScreenTwo(user.userId);
    }
  };

  const endDndSession = async () => {
    await handleDndEvent();
  };

  useEffect(() => {
    return () => {
      // Cleanup on unmount
      endDndSession();
      closeTimerModal();
      closeModalNoLimit();
      dispatch(setEventMain(""));
      dispatch(setEventOther(""));
      dispatch(setEventHRTech(""));
    };
  }, []);

  return (
    <>
      <div className=" w-full flex justify-between items-center h-full">
        <div className="flex justify-start items-center gap-3 h-[15vh] w-[30%]">
          <canvas
            id="canvas"
            style={{ display: "none", marginRight: 10 }}
          ></canvas>

          {streamInstance.localVideoStream &&
            !mediaReducer.localVideoPaused &&
            (videoOption.videoOption === "blur" ||
              videoOption.videoOption === "background") &&
            props.segmentationConfig && (
              <canvas
                // The key attribute is required to create a new canvas when switching
                // context mode
                id="blur"
                style={{ display: "none" }}
                key={props.segmentationConfig.pipeline}
                ref={canvasRef}
              // width={640}
              // height={480}
              />
            )}

          {!mediaReducer.localVideoPaused && !videoInUse ? (
            [
              <Video
                userId={user.userId}
                src={streamInstance.localVideoStream}
                style={{
                  width:
                    videoOption.videoOption === "blur" ||
                      videoOption.videoOption === "background"
                      ? "0"
                      : "33.333%",
                  height:
                    videoOption.videoOption === "blur" ||
                      videoOption.videoOption === "background"
                      ? "0"
                      : "50%",
                }}
                className={` w-1/3 h-1/2 rounded-xl shadow-xl  ${verifyFace.recurrentCheck
                  ? "border-[2px] border-green-500"
                  : !verifyFace.faceVerification
                    ? "border-[2px] border-red-600"
                    : verifyFace.wrongPerson
                      ? "border-[2px] border-yellow-400"
                      : "border-[2px] border-white"
                  }   face_detection_node  ${videoOption.videoOption === "blur" ||
                    videoOption.videoOption === "background"
                    ? "opacity-0"
                    : ""
                  }`}
                onLoad={setSourcePlayback}
              />,
              (videoOption.videoOption === "blur" ||
                videoOption.videoOption === "background") &&
              props.segmentationConfig && (
                <>
                  <div className={` shadow-xl w-[30%] rounded-xl h-1/2 flex justify-center items-center py-[.1rem] px-[.25rem] ${verifyFace.recurrentCheck
                    ? " border-[2px] border-green-500"
                    : !verifyFace.faceVerification
                      ? "border-[2px] border-red-600"
                      : verifyFace.wrongPerson
                        ? "border-[2px] border-yellow-400"
                        : "border-none"
                    }`}>

                    <canvas
                      // The key attribute is required to create a new canvas when switching
                      // context mode
                      key={props.segmentationConfig.pipeline}
                      ref={canvasDisplayRef}
                      className={` w-[28%] h-1/2    `}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </>
              ),
            ]
          ) : (
            <div
              className={` bg-white shadow-xl  rounded-xl h-1/2  w-1/3 flex  justify-center items-center relative   border-[1.5px] border-blue`}
            >
              {personalization.personalization &&
                personalization.personalization?.imageUrl &&
                imageError ? (
                <img
                  className={`inline-block h-9 w-9 rounded-full ring-2 ring-white ${peerVolume && getVolumeClasses(peerVolume[user.userId])
                    }`}
                  src={personalization.personalization.imageUrl}
                  alt=""
                />
              ) : personalization.personalization &&
                personalization.personalization?.imageUrl &&
                !imageError ? (
                <img
                  className={`inline-block h-9 w-9 rounded-full ring-2 ring-white ${peerVolume && getVolumeClasses(peerVolume[user.userId])
                    }`}
                  src={generateThumbnailsUrl(
                    personalization.personalization.imageUrl.split("/").pop(),
                    360,
                    user.custId,
                    user.userId,
                    spacesConfig
                  )}
                  alt=""
                  onError={() => setImageError(true)}
                />
              ) : (
                <p
                  className={` lg:w-4 lg:h-4 xl:h-10 xl:w-10 lg:p-5 xl:p-7 lg:text-sm xl:text-base  bg-blue rounded-full text-white flex justify-center items-center font-semibold ${peerVolume && getVolumeClasses(peerVolume[user.userId])
                    }`}
                >
                  {getInitials(`${user.firstName} ${user.lastName}`)}
                </p>
              )}
              {/* <p className="xl:text-xs text-[9px] p-[.5px] px-[2px]  absolute xl:bottom-1 lg:bottom-[.1rem] xl:left-1 lg:left-[.15rem] rounded-md  text-white bg-gray-500 backdrop-blur-sm opacity-70 w-fit max-w-[100%] truncate  ">
                {user.firstName + " " + user.lastName}
              </p> */}
            </div>
          )}

          {!mediaReducer.localScreenStopped ? (
            <Video
              src={streamInstance.localScreenStream}
              style={{ width: "33.333%", height: "50%" }}
              className=" w-1/3  h-1/2 rounded-xl shadow-xl"
            />
          ) : null}
          {!mediaReducer.localScreenStoppedTwo ? (
            <Video
              src={streamInstance.localSecondScreenStream}
              style={{ width: "33.333%", height: "50%" }}
              className="w-1/3 h-1/2 rounded-xl shadow-xl"
            />
          ) : null}
        </div>

        <div className=" flex justify-end items-end h-full pb-3 w-[70%]">

          {

            (mediaReducer.localVideoPaused &&
              (!agentCamAlwaysOn || constantMethod.checkHigherRole(user.role)) &&
              deviceReducer.activeVideoDevice !== "" ? (
              <div
                className={`relative flex justify-center items-center transition-all duration-100 hover:opacity-80 cursor-pointer ${!mediaReducer.isVideoProducerCreated ? "opacity-80 cursor-not-allowed" : ''}`}
                onClick={() => {
                  // if (mediaReducer.isVideoProducerCreated) {
                    videoPauseOrUnpause();
                  // }
                }}
              >
                <div className=" lg:border-t-[1.4px] xl:border-t-2 border-white lg:w-[40%] xl:w-[43%] absolute -translate-x-1/2 lg:right-[25%] xl:right-[20%] rotate-45"></div>

                <VideoCameraIcon
                  className="testingVideo xl:w-11 w-9 lg:border-[1.4px] xl:border-2px border-blue   text-white p-2 rounded-full shadow-md mr-5 bg-blue cursor-pointer    "
                  title="Turn On Video"
                />
              </div>
            ) : deviceReducer.activeVideoDevice !== "" ? (
              !agentCamAlwaysOn || constantMethod.checkHigherRole(user.role) ? (
                <div className=" flex justify-center items-center relative">
                  <VideoCameraIcon
                    className="xl:w-11 w-9   text-gray-500 bg-white p-2 rounded-full shadow-md mr-5  cursor-pointer   transition-all duration-100 hover:opacity-80 "
                    title="Turn Off Video"
                    onClick={() => {
                      // if (mediaReducer.isVideoProducerCreated) {
                        videoPauseOrUnpause();
                      // }
                    }}
                  />
                  <UserIcon
                    onClick={handleToggleVideoOptions}
                    title="Video Options"
                    className=" text-gray-500 bg-white h-4 w-4 p-[1px] absolute bottom-0 right-3 z-10 cursor-pointer shadow-md rounded-full"
                  />

                  {showVideoOptions && (
                    <div className=" absolute bottom-4 left-9 flex flex-col sm:md:lg:w-[118px] xl:w-[143px] justify-start items-start bg-white z-20 rounded-lg shadow-md p-1 " ref={videoOptionsRef}>
                      {videoOptions.map((val) => (
                        <p
                          onClick={() => handleVideoOptionsClick(val.value)}
                          title={val.title}
                          key={val.id}
                          className={` sm:md:lg:text-xs ${videoOption.videoOption === val.value
                            ? "text-blue"
                            : "text-gray-900"
                            } xl:text-sm  p-1 cursor-pointer hover:bg-gray-100 rounded-md w-full`}
                        >
                          {val.title}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <div className=" flex justify-center items-center relative ml-3">
                  <UserIcon
                    onClick={handleToggleVideoOptions}
                    title="Video Options"
                    className=" text-gray-500 bg-white xl:w-11 w-9 p-2  mr-5 z-10 cursor-pointer shadow-md rounded-full"
                  />

                  {showVideoOptions && (
                    <div className=" absolute bottom-7 left-0 flex flex-col sm:md:lg:w-[118px] xl:w-[143px] justify-start items-start bg-white z-20 rounded-lg shadow-md p-1 " ref={videoOptionsRef}>
                      {videoOptions.map((val) => (
                        <p
                          onClick={() => handleVideoOptionsClick(val.value)}
                          title={val.title}
                          key={val.id}
                          className={` sm:md:lg:text-xs ${videoOption.videoOption === val.value ? "text-blue" : "text-gray-900"
                            } xl:text-sm  p-1 cursor-pointer hover:bg-gray-100 rounded-md w-full`}
                        >
                          {val.title}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              )
            ) : null)
          }


          {roomReducer.isAudioAllowedInRoom &&
            deviceReducer.activeAudioDevice !== "" &&
            (!mediaReducer.localAudioPaused ? (
              <MicrophoneIcon
                className={`xl:w-11  w-9  text-gray-500 p-2 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 ${!mediaReducer.isAudioProducerCreated ? "opacity-80 cursor-not-allowed" : ''} `}
                title="Mute"
                onClick={() => {
                  if (mediaReducer.isAudioProducerCreated) {
                    audioPauseOrUnpause();
                  }
                }}
              />
            ) : (
              <div
                className={` relative flex justify-center items-center transition-all duration-100 hover:opacity-80 cursor-pointer ${!mediaReducer.isAudioProducerCreated ? "opacity-80 cursor-not-allowed" : ''}`}
                onClick={() => {
                  if (mediaReducer.isAudioProducerCreated) {
                    audioPauseOrUnpause();
                  }
                }}
              >
                <div className=" lg:border-t-[1.4px] xl:border-t-2 border-white lg:w-[40%] xl:w-[43%] absolute -translate-x-1/2 lg:right-[25%] xl:right-[20%] rotate-45"></div>
                <MicrophoneIcon
                  className="xl:w-11 lg:w-9 lg:border-[1.4px] xl:border-2px border-blue   text-white p-2 rounded-full shadow-md mr-5 bg-blue cursor-pointer    "
                  title="Unmute"
                />
              </div>
            ))
          }




          {/*

                      * Use  'text-gray-500' color class if no screen is been shared
                      * Just comment the p tag of number to hide the number in no screen sharing, or make it condition wise.

                      */}

          {mediaReducer.localScreenStopped ? (
            <TvIcon
              className="xl:w-11 w-9   text-gray-500 p-2 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 "
              title="Present Screen 1"
              onClick={() => {
                startStopScreen();
              }}
            />
          ) : (
            <p className="relative flex justify-center items-center">
              <TvIcon
                className="xl:w-11 w-9   text-blue p-2 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 "
                title="Present Screen 1"
                onClick={() => {
                  startStopScreen();
                }}
              />{" "}
              {
                (constantMethod.checkHigherRole(user.role) || (!constantMethod.checkHigherRole(user.role) && (!screenControl && !user.enableDesktopApp))) &&
                <p className=" bg-blue text-white rounded-full p-1 absolute right-4 -bottom-1 w-4 h-4 flex justify-center items-center text-[.6rem]">
                  1
                </p>
              }
            </p>
          )}

          {(!mediaReducer.localScreenStopped ||
            !mediaReducer.localScreenStoppedTwo) &&
            roomReducer.viewMode !== roomViewModeConst.collabMode &&
            (constantMethod.checkHigherRole(user.role) ||
            (!constantMethod.checkHigherRole(user.role) && (!screenControl && !user.enableDesktopApp))) ? (
            mediaReducer.localScreenStoppedTwo ? (
              <p className="relative flex justify-center items-center">
                <TvIcon
                  className="xl:w-11 w-9   text-gray-500 p-2 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 "
                  title="Present Screen 2"
                  onClick={() => {
                    startStopScreenTwo();
                  }}
                />{" "}
                <p className=" bg-gray-500 text-white rounded-full p-1 absolute right-4 -bottom-1 w-4 h-4 flex justify-center items-center text-[.6rem]">
                  2
                </p>
              </p>
            ) : (
              (constantMethod.checkHigherRole(user.role) || (!constantMethod.checkHigherRole(user.role) && (!screenControl && !user.enableDesktopApp))) &&(
              <p className="relative flex justify-center items-center">
                <TvIcon
                  className="xl:w-11 w-9   text-blue p-2 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 "
                  title="Present Screen 2"
                  onClick={() => {
                    startStopScreenTwo();
                  }}
                />{" "}
                <p className=" bg-blue text-white rounded-full p-1 absolute right-4 -bottom-1 w-4 h-4 flex justify-center items-center text-[.6rem]">
                  2
                </p>
              </p>)
            )
          ) : null}

          <p className="relative">
          {(chat.emojiIconHRTech === "Handraise" || chat.emojiIconHRTech === "technicalissue" || chat.emojiIconOther === "Smile" || chat.emojiIconOther === "Yes" || chat.emojiIconOther === "No" || chat.emojiIconOther === "Canthear" || chat?.emojiIconMain==="DND" ) && <div className=" w-2 h-2 bg-red-600 rounded-full absolute top-0 left-0 z-10"></div> }
            <EllipsisVerticalIcon
              className="xl:w-11 w-9   text-gray-500 p-2 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer   transition-all duration-100 hover:opacity-80 "
              title="Options"
              onClick={handleShowOptions}
            />
            {showOptions && (
              <div className="bg-white max-h-60 scrollbar overflow-x-hidden overflow-y-auto absolute bottom-8 right-[10%] p-1 rounded-xl shadow-md w-[15vw] transition duration-500 z-10" ref={controlsModalRef}>
                {options.map((option) =>
                  !higherRoleOptions.includes(option.title) ? (
                    <div
                      className={`relative flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl p-1 ${chat.emojiIconOther === option.type || chat.emojiIconHRTech === option.type ? "bg-blue-50" : ""}`}
                      key={option.title}
                      onClick={() => handleOptionClick(option)}
                    >
                      {(chat.emojiIconOther === option.type || chat.emojiIconHRTech === option.type)&& <div className=" w-2 h-2 bg-red-600 rounded-full absolute top-1 left-0 z-10"></div> }
                      <option.Icon
                        className={`h-9 w-9 p-2 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer transition-all duration-100 hover:opacity-80 ${chat.emojiIconOther === option.type || chat.emojiIconHRTech === option.type
                          ? "text-blue"
                          : "text-gray-500"
                          }`}
                        title={option.title}
                      />
                      <p
                        className={`lg:text-sm xl:text-base ${chat.emojiIconOther === option.type || chat.emojiIconHRTech === option.type
                          ? "text-blue"
                          : "text-gray-500"
                          }`}
                      >
                        {option.title}
                      </p>
                    </div>
                  ) : (
                    constantMethod.checkHigherRole(user.role) && (
                      <div
                        className={`relative flex justify-start items-center cursor-pointer hover:bg-gray-50 transition-all rounded-xl p-1 ${(chat.emojiIconOther === option.type || chat.emojiIconHRTech === option.type || (chat?.emojiIconMain===option.type && chat?.emojiIconMain==="DND"))  ? "bg-blue-50" : ""
                          }`}
                        key={option.title}
                        onClick={() => handleOptionClick(option)}
                      >
                        {(chat?.emojiIconMain===option.type && chat?.emojiIconMain==="DND")&& <div className=" w-2 h-2 bg-red-600 rounded-full absolute top-1 left-0 z-10"></div> }
                        <option.Icon
                          className={`h-9 w-9 p-2 rounded-full shadow-md mr-5 bg-gray-50 cursor-pointer transition-all duration-100 hover:opacity-80 ${chat.emojiIconOther === option.type || chat.emojiIconHRTech === option.type || (chat?.emojiIconMain===option.type && chat?.emojiIconMain==="DND")
                            ? "text-blue"
                            : "text-gray-500"
                            }`}
                          title={option.title}
                        />
                        <p
                          className={`lg:text-sm xl:text-base ${chat.emojiIconOther === option.type || chat.emojiIconHRTech === option.type || (chat?.emojiIconMain===option.type && chat?.emojiIconMain==="DND")
                            ? "text-blue"
                            : "text-gray-500"
                            }`}
                        >
                          {option.title}
                        </p>
                      </div>
                    )
                  )
                )}
              </div>
            )}
          </p>

          <ArrowRightStartOnRectangleIcon
            className="xl:w-11 w-9   text-white p-2 rounded-full shadow-md mr-5 bg-red-600 cursor-pointer   transition-all duration-100 hover:opacity-80 "
            onClick={goToDashboard}
            title="Go to Dashboard"
          />
          <SignalIndicator networkStrength={mediaReducer.networkStrength} />
        </div>
      </div>

      {/* Upload Audio and Video Modal  */}

      <Transition appear show={isUploadModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden backdrop-blur-sm">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-[40%] sm:max-w-[50%] max-h-[70vh] overflow-y-scroll scrollbar sm:p-6">
                  <p
                    className=" absolute right-0 top-2 text-md text-gray-900 cursor-pointer"
                    title="Close"
                    onClick={closeUploadModal}
                  >
                    <XMarkIcon className=" h-5 w-5" />
                  </p>
                  <Dialog.Title
                    as="h3"
                    className="xl:text-lg lg:text-base text-center font-bold leading-6 text-gray-900"
                  >
                    Upload Video and Audio
                  </Dialog.Title>
                  <div className="mt-2">
                    <UploadVideoAudio isUploadModalOpen={isUploadModalOpen} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Virtual Background  */}
      <Transition appear show={isVirtualModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => { }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden backdrop-blur-sm">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-[40%] sm:max-w-[50%] max-h-[70vh] overflow-y-scroll scrollbar sm:p-6">
                  <p
                    className=" absolute right-0 top-2 text-md text-gray-900 cursor-pointer"
                    title="Close"
                    onClick={closeVirtualModal}
                  >
                    <XMarkIcon className=" h-5 w-5" />
                  </p>
                  <Dialog.Title
                    as="h3"
                    className="xl:text-lg lg:text-base text-center font-bold leading-6 text-gray-900"
                  >
                    Virtual Background
                  </Dialog.Title>
                  <div className="mt-2">
                    <VirtualBackground closeVirtualModal={closeVirtualModal} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {props.isDndModalVisible && (
        <WarningModal
          title="End DND?"
          message="This will end the DND session. Proceed?"
          confirmButtonText="Yes"
          cancelButtonText="No"
          type="info"
          onConfirm={() => {
            endDndSession();
            props.dndModalControl(false);
            props.stopDndTimer();
          }}
          onCancel={() => {
            props.dndModalControl(false);
          }}
        />
      )}

      <ControlActionModal
        isOpen={timerModalOpen}
        setIsOpen={setTimerModalOpen}
        closeControlActionModal={closeTimerModal}
        selectedEvent={clickedEvent}
        for=""
      />
      <ControlActionModalWithoutTimeLimit
        isOpen={noLimitTimerModal}
        setIsOpen={setNoLimitTimerModal}
        closeModalNoLimit={closeModalNoLimit}
        selectedEvent={clickedEvent}
        for=""
      />
    </>
  );
}
